import jsSHA from 'jssha';
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export function getTgsWebVersion() {
	return 'v 1.623.1009 2024-12-13 10:58';
}
// version special avec retour en arriere suite a l'integration de work.vue de maxime...

export const getParamVersion = () => {
	const sha = new jsSHA('SHA-256', 'TEXT');
	sha.update(getTgsWebVersion());

	const versionToSha = sha.getHash('HEX');

	return '?' + versionToSha;
};

export const getBackVersion = async () => {
	const {
		data: { GetBackEndVersion }
	} = await apollo.query({
		query: gql`
			query {
				GetBackEndVersion {
					backEndVersion
					shortBackEndVersion
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return GetBackEndVersion;
};
